import * as React from "react";
const SvgUserManagementIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 80 80"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#4899B7" />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M40.31 54.782c1.939 0 3.51-1.557 3.51-3.478 0-1.922-1.571-3.479-3.51-3.479-1.94 0-3.511 1.557-3.511 3.478 0 1.922 1.572 3.479 3.51 3.479ZM35.043 60c0-1.38.554-2.704 1.541-3.682a5.318 5.318 0 0 1 3.725-1.535 5.319 5.319 0 0 1 3.725 1.535A5.184 5.184 0 0 1 45.575 60M54.353 54.782c1.938 0 3.51-1.557 3.51-3.478 0-1.922-1.572-3.479-3.51-3.479-1.94 0-3.511 1.557-3.511 3.478 0 1.922 1.572 3.479 3.51 3.479ZM49.088 60c0-1.383.556-2.71 1.545-3.689a5.305 5.305 0 0 1 3.73-1.528 5.304 5.304 0 0 1 3.719 1.535A5.188 5.188 0 0 1 59.62 60M26.267 54.782c1.939 0 3.51-1.557 3.51-3.478 0-1.922-1.571-3.479-3.51-3.479-1.94 0-3.511 1.557-3.511 3.478 0 1.922 1.572 3.479 3.51 3.479ZM21 60c0-1.38.553-2.704 1.539-3.682a5.305 5.305 0 0 1 3.718-1.535c1.4 0 2.74.55 3.73 1.528a5.189 5.189 0 0 1 1.545 3.69M26.268 42.609v-6.957h28.086v6.957M40.309 42.608V30.435M40.31 30.435c2.908 0 5.265-2.336 5.265-5.218 0-2.881-2.357-5.217-5.266-5.217-2.908 0-5.266 2.336-5.266 5.217 0 2.882 2.358 5.218 5.266 5.218Z"
      {...props}
    />
  </svg>
);
export default SvgUserManagementIcon;
