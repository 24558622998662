import * as React from "react";
const SvgDashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 80 80"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#4899B7" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="M32.287 36.95v11.398M40.063 31.496v16.852M47.715 42.973v5.374"
      {...props}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="M47.81 23.323H32.191c-5.444 0-8.857 3.842-8.857 9.28v14.67c0 5.438 3.397 9.28 8.857 9.28h15.62c5.46 0 8.856-3.842 8.856-9.28v-14.67c0-5.438-3.397-9.28-8.857-9.28Z"
      clipRule="evenodd"
      {...props}
    />
  </svg>
);
export default SvgDashboardIcon;
