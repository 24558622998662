import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./CentralStore/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import {
  fetchAllModules,
  fetchMineDetails,
  fetchProfileData,
} from "./CentralStore/ExportLMSTable/dataSlice";

const root = ReactDOM.createRoot(document.getElementById("root"));

function AppComponent() {
  const dispatch = useDispatch();

  const { profileDataStatus, mineDetailsStatus, allModulesStatus } =
    useSelector((state) => state.data);

  const onTokenReciveHandler = (tokens) => {
    const { token } = tokens;
    localStorage.setItem("accessToken", token);
    if (mineDetailsStatus === "idle") dispatch(fetchMineDetails(token));
    if (allModulesStatus === "idle") dispatch(fetchAllModules(token));
    if (profileDataStatus === "idle") dispatch(fetchProfileData());
  };

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: "login-required" }}
      authClient={keycloak}
      onTokens={onTokenReciveHandler}
    >
    <BrowserRouter>
       <App />
    </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

root.render(
  <Provider store={store}>
    <AppComponent />
  </Provider>
);
