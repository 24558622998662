import * as React from "react";
const SvgEventMgmtIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 80 80"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#4899B7" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M40 33.206v8.965M22.92 47.1c-2.039 3.53-3.057 5.296-2.905 6.745a4.483 4.483 0 0 0 1.823 3.157c1.178.856 3.215.856 7.289.856h21.746c4.074 0 6.11 0 7.288-.856a4.484 4.484 0 0 0 1.824-3.157c.152-1.449-.866-3.215-2.904-6.745L46.21 28.275c-2.038-3.53-3.057-5.295-4.388-5.887a4.484 4.484 0 0 0-3.647 0c-1.33.592-2.349 2.356-4.385 5.884L22.92 47.102Zm17.194 1.794v.224h-.226v-.224h.226Z"
      strokeWidth={3}
      {...props}
    />
  </svg>
);
export default SvgEventMgmtIcon;
