import * as React from "react";
const SvgSettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#4899B7"
      d="M20 26.25c-3.45 0-6.25-2.8-6.25-6.25s2.8-6.25 6.25-6.25 6.25 2.8 6.25 6.25-2.8 6.25-6.25 6.25Zm0-10A3.756 3.756 0 0 0 16.25 20 3.756 3.756 0 0 0 20 23.75 3.756 3.756 0 0 0 23.75 20 3.756 3.756 0 0 0 20 16.25Z"
    />
    <path
      fill="#4899B7"
      d="M25.35 36.984c-.35 0-.7-.05-1.05-.133a4.092 4.092 0 0 1-2.45-1.85l-.2-.333c-.983-1.7-2.333-1.7-3.316 0l-.183.316a3.996 3.996 0 0 1-2.45 1.867 3.902 3.902 0 0 1-3.05-.416l-2.867-1.65a4.426 4.426 0 0 1-1.633-6.033c.483-.85.616-1.617.333-2.1-.283-.484-1-.768-1.983-.768a4.424 4.424 0 0 1-4.417-4.416v-2.933a4.424 4.424 0 0 1 4.417-4.417c.983 0 1.7-.284 1.983-.767.283-.483.167-1.25-.333-2.1a4.436 4.436 0 0 1-.434-3.35 4.3 4.3 0 0 1 2.067-2.683l2.883-1.65c1.884-1.117 4.367-.467 5.5 1.45l.2.333c.984 1.7 2.334 1.7 3.317 0l.183-.317c1.134-1.933 3.617-2.583 5.517-1.45l2.867 1.65a4.426 4.426 0 0 1 1.633 6.033c-.483.85-.617 1.617-.333 2.1.283.484 1 .767 1.983.767a4.424 4.424 0 0 1 4.417 4.417v2.934a4.424 4.424 0 0 1-4.417 4.416c-.983 0-1.7.284-1.983.767-.284.483-.167 1.25.333 2.1a4.361 4.361 0 0 1 .433 3.35 4.3 4.3 0 0 1-2.066 2.683l-2.884 1.65a4.147 4.147 0 0 1-2.016.533ZM20 30.818c1.484 0 2.867.933 3.817 2.583l.184.317c.2.35.533.6.933.7.4.1.8.05 1.133-.15l2.884-1.667c.433-.25.766-.666.9-1.166.133-.5.066-1.017-.184-1.45-.95-1.634-1.066-3.317-.333-4.6.733-1.284 2.25-2.017 4.15-2.017 1.067 0 1.917-.85 1.917-1.917v-2.933c0-1.05-.85-1.917-1.917-1.917-1.9 0-3.417-.733-4.15-2.016-.733-1.284-.617-2.967.333-4.6.25-.434.317-.95.184-1.45-.134-.5-.45-.9-.884-1.167l-2.883-1.65c-.717-.434-1.667-.184-2.1.55l-.183.316c-.95 1.65-2.334 2.584-3.817 2.584s-2.867-.934-3.817-2.584l-.183-.333a1.53 1.53 0 0 0-2.067-.533l-2.883 1.666a1.928 1.928 0 0 0-.717 2.617c.95 1.633 1.067 3.317.334 4.6-.734 1.283-2.25 2.017-4.15 2.017-1.067 0-1.917.85-1.917 1.917v2.933c0 1.05.85 1.916 1.917 1.916 1.9 0 3.416.734 4.15 2.017.733 1.284.616 2.967-.334 4.6-.25.433-.316.95-.183 1.45s.45.9.883 1.167l2.884 1.65c.35.216.766.267 1.15.166.4-.1.733-.366.95-.716l.183-.317c.95-1.633 2.333-2.583 3.817-2.583Z"
    />
  </svg>
);
export default SvgSettingsIcon;
