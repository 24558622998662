import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { chngPwSchema } from "./PersonalDetailsConfig";
import { useKeycloak } from "@react-keycloak/web";
import { changePassword, appserveraddr } from "../../../Services/EndPoints";
import { putDataWithBodyPf } from "../../../Services/ProfileServices";
import LMSButton from "../../LMSButton/LMSButton";
import lmsToast from "../../CustomToastifier/CustomToastifier";

export default function ChangePasswordComp({ togglePopup }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(chngPwSchema), mode: "all" });
  const { keycloak } = useKeycloak();
  const submitHandler = (data) => {
    if (data.new_password === data.retype_password) {
      putDataWithBodyPf(changePassword, data)
        .then((res) => {
          if (res?.response?.status === 400) {
            setTimeout(() => {
              lmsToast({ toasterType: "error", msg: res?.response?.data });
            }, 300);
          } else {
            togglePopup();
            lmsToast({ toasterType: "success", operationType: "pwChange" });
            localStorage.clear();
            keycloak.logout({ redirectUri: appserveraddr });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="password_input">
        <label>
          Old Password<span className="asterisk">*</span>
        </label>
        <input type="text" className="" {...register("old_password")} />
        <div className="error__message">{errors["old_password"]?.message}</div>
      </div>
      <div className="password_input">
        <label>
          New Password<span className="asterisk">*</span>
        </label>
        <input type="password" {...register("new_password")} />
        <div className="error__message">{errors["new_password"]?.message}</div>
      </div>
      <div className="password_input">
        <label>
          Confirm Password<span className="asterisk">*</span>
        </label>
        <input type="password" {...register("retype_password")} />
        <div className="error__message">
          {errors["retype_password"]?.message}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <LMSButton
          label="save"
          variant="success"
          size="btn_sm"
          onClick={handleSubmit(submitHandler)}
        />
      </div>
    </form>
  );
}
