import React, { useEffect, useState, useRef } from "react";
import Popover from "react-bootstrap/Popover";
import Notification from "./Notification/Notification";
import Overlay from "react-bootstrap/Overlay";
import { NotificationIcon, Badge } from "../../IconComponents";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [seen, setSeen] = useState(false);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShowPopover((prevState) => !prevState);
    setTarget(event.target);
    if (showPopover === false) {
      setSeen(false);
    }
  };
  useEffect(() => {
    const handleWindowClick = (event) => {
      if (showPopover && ref.current && !ref.current.contains(event.target)) {
        setShowPopover(false);
      }
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [showPopover]);

  // useEffect(() => {
  // socket.emit("notification", "Please emit the data");
  // socket.on("getnotification", (data) => {
  //   console.log(data, "data");
  //   setNotifications((prevNotifications) => [...prevNotifications, data]);
  //   setSeen(true);
  // });
  // }, []);

  return (
    <div className=" p-1 notification cursor-pointer" ref={ref}>
      <NotificationIcon
        width="1.6em"
        height="1.6em"
        color="var( --color-A)"
        onClick={handleClick}
      />
      {seen && (
        <span className="notification__badge">
          <Badge width="0.6em" height="0.6em" />
        </span>
      )}
      {/* {seen && <span className="counter-badge">{notifications?.length}</span>} */}
      {/* {seen && (
        <div class="circles">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <NotificationIcon
            width="1.2em"
            height="1.2em"
            fill="var(--white3-color)"
            onClick={handleClick}
          />
        </div>
      )} */}

      <Overlay
        show={showPopover}
        target={target}
        placement="bottom"
        container={ref}
      >
        <Popover id="popover-contained">
          <Popover.Header
            as="h3"
            style={{ color: "var(--color-B)", opacity: 0.5 }}
          >
            Today
          </Popover.Header>
          <Popover.Body>
            {notifications?.length > 0 && <Notification data={notifications} />}
            <div className="text-center text-decoration-underline cursor-pointer">
              {notifications?.length > 0
                ? "View All"
                : "No Notifications Found"}
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default Notifications;
