const { hostname, protocol } = window.location;

let tempUserMgmtService;
let tempAppServerAddr;

if (hostname === "localhost") {
  tempUserMgmtService = process.env.REACT_APP_USER_BASE_URL;
  tempAppServerAddr = process.env.REACT_APP_APP_SERVER_ADDRESS;
} else {
  tempUserMgmtService = `${protocol}//${hostname}`;
  tempAppServerAddr = `${protocol}//${hostname}`;
}

export const baseUrl = ""; //update whenever required
export const appserveraddr = tempAppServerAddr;
export const deviceBaseUrl = ""; //update whenever required
export const userMgmtService = tempUserMgmtService;
export const authBaseUrl = tempUserMgmtService;

//permissions
export const getAllPermissions = "/auth/permissions/permissions";
export const managemaster = "/lms/master-data";
export const getMineDetails = "/lms/mines/mines";

export const getAppModules = "/auth/permissions/modules";

//user image
export const getUserImage = "/auth/media/file/";
export const postImage = "/auth/profile/change_picture";
export const authUserData = "/auth/profile";
export const vehicleChecklistReports = "/lms/vehicle_inspection/report";
export const changePassword = "/auth/profile/change_password";
