import React, { useState, useRef, useEffect } from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import {
  Dark,
  Light,
  ThemeSelectionIcon,
  TickCircle,
} from "../../IconComponents";
import "./ThemesPopUp.css";
import { useDispatch, useSelector } from "react-redux";
import { setPresentTheme } from "../../../CentralStore/ExportLMSTable/dataSlice";
const ThemesPopUp = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const dispatch = useDispatch();
  const [iconClicked, setIconClicked] = useState(false);
  const ref = useRef(null);
  const handleClick = (event) => {
    setShowPopover((prevState) => !prevState);
    setIconClicked((prevState) => !prevState);
    setTarget(event.target);
  };
  const { presentTheme } = useSelector((state) => state.data);
  useEffect(() => {
    const handleWindowClick = (event) => {
      if (showPopover && ref.current && !ref.current.contains(event.target)) {
        setShowPopover(false);
        setIconClicked(false);
      }
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [showPopover]);
  return (
    <div className="themeicon p-1  cursor-pointer" ref={ref}>
      <ThemeSelectionIcon
        width="1.6em"
        height="1.6em"
        stroke="var(--color-A)"
        onClick={handleClick}
      />

      <Overlay
        show={showPopover}
        target={target}
        placement="bottom"
        container={ref}
      >
        <Popover id="popover-contained">
          <Popover.Header as="h3" className="mode_label">
            Select Theme
          </Popover.Header>
          <Popover.Body>
            <div className="themepopup cursor-pointer d-flex">
              <div>
                <div
                  className={` text-center theme-card ${
                    presentTheme === "contrast" ? "selected-theme" : ""
                  }`}
                  onClick={() => dispatch(setPresentTheme("contrast"))}
                >
                  <Light height="3.5em" width="6em" fill="#ffffff" />
                  {presentTheme === "contrast" && (
                    <TickCircle className="tick-overlay" />
                  )}
                </div>
                <span className="d-block theme__font">Light</span>
              </div>

              <div>
                <div
                  className={` text-center theme-card ${
                    presentTheme === "dark" ? "selected-theme" : ""
                  }`}
                  onClick={() => dispatch(setPresentTheme("dark"))}
                >
                  <Dark height="3.5em" width="6em" fill="#ffffff" />
                  {presentTheme === "dark" && (
                    <TickCircle className="tick-overlay" />
                  )}
                </div>
                <span className="d-block theme__font">Dark</span>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default ThemesPopUp;
