import {
  BuyersIcon,
  DashboardBuilderIcon,
  DashboardIcon,
  Iccc,
  EventMgmtIcon,
  GisMappingIcon,
  IrcIcon,
  ServiceConfiguration,
  UserManagementIcon,
  VideoMgmtIcon,
} from "../Components/IconComponents";
import IcccIcon from "../Components/IconComponents/IcccIconn";

export const modules = [
  {
    name: "dashboard",
    widgetname: "Dashboards",
    icon: (
      <DashboardIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />
    ),
    projectlink: "/client/converge",
    text: "Integrate any system to ingest data from FTP, HTTP or Database and generate and view Dashboards & Reports",
  },
  {
    name: "devices",
    widgetname: "Devices",
    icon: (
      <IcccIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />
    ),
    projectlink: "/client/iccc",
    text: "View dashboards and manage all IoT devices including Smart Light, Environment Sensors, Public Announcement System, Emergency Call Box etc...",
  },
  {
    name: "event_mgmt",
    widgetname: "Event Management",
    icon: (
      <EventMgmtIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />
    ),
    projectlink: "/client/event-management",
    text: "Manage events and incident, create SoPs, Generate Alert and Notifications and execute SoP",
  },
  {
    name: "asset_mgmt",
    widgetname: "Asset Management",
    icon: (
      <EventMgmtIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />
    ),
    projectlink: "/client/asset-management",
    text: "Manage IoT assets and monitor the assets for availability",
  },
  {
    name: "irc",
    widgetname: "Information Resource Center",
    icon: <IrcIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />,
    projectlink: "/client/irc",
    text: "Manage all your knowledge base in form of documents, presentations and data sheets at one location for search and access",
  },
  {
    name: "video_management",
    widgetname: "Video Management",
    icon: (
      <VideoMgmtIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />
    ),
    projectlink: "/client/vms",
    text: "Add surveillance cameras to record and stream for live monitoring, playback recorded videos etc...",
  },
  {
    name: "gis_mapping",
    widgetname: "GIS Mapping",
    icon: (
      <GisMappingIcon width="3.5rem" height="3.5rem" stroke="var(--color-E)" />
    ),
    projectlink: "/client/vehicle-inspection",
    text: "View and search geo spatial information using a GIS Map",
  },
  {
    name: "user_management",
    widgetname: "User Management",
    icon: (
      <UserManagementIcon
        width="3.5rem"
        height="3.5rem"
        stroke="var(--color-E)"
      />
    ),
    projectlink: "/client/user-management",
    text: "Manage users, roles and permissions for the converge application suite",
  },
  
 
];
