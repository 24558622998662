import * as React from "react";
const SvgThemeSelectionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 33 32"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={2}
      {...props}
      d="M29.604 23.18c-.516-.477-1.274-.594-1.922-.703-.899-.149-1.242-.313-1.766-.782-1.117-.992-1.117-2.43 0-3.422l2.367-2.101c3.625-3.203 3.625-8.453 0-11.656C25.611 2.164 22.025 1 18.3 1 13.947 1 9.4 2.586 5.893 5.695c-6.524 5.766-6.524 15.211 0 20.977C9.135 29.539 13.51 30.969 17.838 31h.133c4.328 0 8.593-1.398 11.625-4.094 1.125-.992.936-2.86.008-3.726Z"
    />
    <path
      fill="#E32326"
      stroke="#E32326"
      d="M9.252 12.246a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    />
    <path
      fill="#D9D9D9"
      stroke="#D9D9D9"
      d="M9.875 20.3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    />
    <path
      fill="#1EAF2C"
      stroke="#1EAF2C"
      d="M15.5 7.25a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    />
    <path
      fill="#17A3A0"
      stroke="#17A3A0"
      d="M19.252 24.672a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M23.629 7.25a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    />
  </svg>
);
export default SvgThemeSelectionIcon;
