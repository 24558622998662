import * as React from "react";
const SvgGisMappingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 80 80"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#4899B7" />
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M52.857 35.714a12.857 12.857 0 0 0-25.715 0c0 5.275 4.22 12.149 12.857 20.383 8.638-8.234 12.858-15.108 12.858-20.383ZM39.999 60c-10.477-9.523-15.714-17.62-15.714-24.286a15.714 15.714 0 1 1 31.429 0c0 6.666-5.237 14.763-15.715 24.286Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M40 37.144a2.857 2.857 0 1 0 0-5.714 2.857 2.857 0 0 0 0 5.714ZM40 40a5.714 5.714 0 1 1 0-11.429 5.714 5.714 0 0 1 0 11.429Zm15.429 8.571L60 60.001H47.143v-2.857H32.857V60H20l4.571-11.429H55.43Zm-3.078 0H27.65l-3.429 8.572h31.56l-3.429-8.572Z"
      {...props}
    />
  </svg>
);
export default SvgGisMappingIcon;
