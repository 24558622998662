import * as React from "react";
const SvgIrcIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 80 80"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#4899B7" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M40.029 31.44h-.222M39.916 49.218 40 37M20.182 43.596a20 20 0 0 0 19.839 16.711 20.002 20.002 0 0 0 9.053-2.221l7.534 1.556a2.222 2.222 0 0 0 2.622-2.623l-1.533-7.556a20.003 20.003 0 1 0-37.515-5.867Z"
      {...props}
    />
  </svg>
);
export default SvgIrcIcon;
